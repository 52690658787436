<script setup>
import { ref, reactive, onMounted, watchEffect } from 'vue'
import axios from 'axios'
import { CONFIG } from '../config/index.js'
import SuccessIcon from '../components/icons/SuccessIcon.vue'

const baseUrl = `${CONFIG.API}`
const projects = ref([])
const props = defineProps({
  projectId: {
    type: String,
    required: false
  },
  token: {
    type: String,
    required: true
  },
  phone: {
    type: String,
    required: false
  },
  phoneText: {
    type: String,
    required: false
  },
  title: {
    type: String,
    required: false
  },
  subtitle: {
    type: String,
    required: false
  },
  modalHeaderAndButtonBackgroundColor: {
    type: String,
    required: false,
    default: null
  },
  buttonTextColor: {
    type: String,
    required: false
  },
  successTitle: {
    type: String,
    required: false,
    default: '¡Gracias por contactarnos!'
  },
  successSubtitle: {
    type: String,
    requred: false,
    default: 'Se abrirá una pestaña con nuestro chat de WhatsApp.'
  },
  customer: {
    type: String,
    required: false
  },
  mobysuiteVersion: {
    type: Number,
    required: false
  },
  utm_source: {
    type: String,
    required: false,
    default: null
  },
  utm_medium: {
    type: String,
    required: false,
    default: null
  },
  utm_campaign: {
    type: String,
    required: false,
    default: null
  },
  utm_term: {
    type: String,
    required: false,
    default: null
  },
  utm_content: {
    type: String,
    required: false,
    default: null
  },
  information_medium: {
    type: String,
    required: false,
    default: null
  },
  source: {
    type: String,
    required: false,
    default: null
  }
})

const urlParams = ref({})

const getParamsFromURL = () => {
  const params = new URLSearchParams(window.location.search)
  urlParams.value = Object.fromEntries(params.entries())
}

// Si necesitas reaccionar a los cambios en la URL
watchEffect(() => window.location.search, getParamsFromURL)

let contact = reactive({
  name: { value: '', alertStatus: 'initial' },
  lastName: { value: '', alertStatus: 'initial' },
  email: { value: '', alertStatus: 'initial' },
  rut: { value: '', alertStatus: 'initial' },
  cellphone: { value: '', alertStatus: 'initial', isValid: false },
  cellphoneDialCode: { value: '', alertStatus: 'initial' },
  projectId: { value: props.projectId, alertStatus: 'initial' }
})

let loading = ref(false)
let sended = ref(false)
let formError = ref(false)
let preferredCountries = ref(['CL', 'AR', 'PE', 'MX'])

const cellphoneOptions = ref({
  placeholder: 'Ingresa tu teléfono'
})

function countryChanged(country) {
  contact.cellphoneDialCode.value = country.dialCode
}

async function sendProspect() {
  if (formValidation()) {
    loading.value = true
    try {
      const response = await axios.post(`${baseUrl}api/v1/leads`, {
        token: props.token,
        name: contact.name.value,
        lastname: contact.lastName.value,
        rut: contact.rut.value,
        email: contact.email.value,
        project_id: contact.projectId.value,
        phone: '+' + contact.cellphoneDialCode.value + contact.cellphone.value.split(' ').join(''),
        utm_source: props.utm_source ? props.utm_source : urlParams.value.utm_source,
        utm_medium: props.utm_medium ? props.utm_medium : urlParams.value.utm_medium,
        utm_campaign: props.utm_campaign ? props.utm_campaign : urlParams.value.utm_campaign,
        utm_term: props.utm_term ? props.utm_term : urlParams.value.utm_term,
        utm_content: props.utm_content ? props.utm_content : urlParams.value.utm_content,
        source: props.information_medium
          ? props.information_medium
          : urlParams.value.information_medium,
        contact: props.source ? props.source : urlParams.value.source
      })
      if (response.status != 200) formError.value = true
      loading.value = false
      sended.value = true
      if (props.phone)
        setTimeout(() => {
          window.open(
            `https://api.whatsapp.com/send?phone=${props.phone}${props.phoneText ? '&text=' : ''}${
              props.phoneText ? props.phoneText : ''
            }`
          )
        }, 2000)
    } catch (error) {
      formError.value = true
      loading.value = false
      sended.value = true
      console.log(error)
    }
  }
}

function yourValidationMethod({ number, valid, country }) {
  contact.cellphone.isValid = valid
  return number, country
}

function formValidation() {
  if (
    contact.name.value &&
    contact.lastName.value &&
    (emailValidation(contact.email.value) ? true : false) &&
    contact.cellphone.isValid &&
    Fn.validaRut(contact.rut.value)
      ? true
      : false
  ) {
    return true
  }

  if (!contact.name.value) contact.name.alertStatus = 'alert'
  if (!contact.lastName.value) contact.lastName.alertStatus = 'alert'
  if (!emailValidation(contact.email.value)) contact.email.alertStatus = 'alert'
  if (!Fn.validaRut(contact.rut.value)) contact.rut.alertStatus = 'alert'
  if (!contact.cellphone.isValid) contact.cellphone.alertStatus = 'alert'
  return false
}

function formateaRut(rut) {
  if (rut != '' && rut.length > 1) {
    var actual = rut.replace(/^0+/, '')
    var sinPuntos = actual.replace(/\./g, '')
    var actualLimpio = sinPuntos.replace(/-/g, '')
    var inicio = actualLimpio.substring(0, actualLimpio.length - 1)
    var rutPuntos = ''
    var i = 0
    var j = 1
    for (i = inicio.length - 1; i >= 0; i--) {
      var letra = inicio.charAt(i)
      rutPuntos = letra + rutPuntos
      if (j % 3 == 0 && j <= inicio.length - 1) {
        rutPuntos = '.' + rutPuntos
      }
      j++
    }
    var dv = actualLimpio.substring(actualLimpio.length - 1)
    rutPuntos = rutPuntos + '-' + dv
    contact.rut.value = rutPuntos
  }
}

var Fn = {
  // Valida el rut con su cadena completa "XXXXXXXX-X"
  validaRut: function (rutCompleto) {
    rutCompleto = rutCompleto.replace(/\./g, '')
    if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto)) return false
    var tmp = rutCompleto.split('-')
    var digv = tmp[1]
    var rut = tmp[0]
    if (digv == 'K') digv = 'k'
    return Fn.dv(rut) == digv
  },
  dv: function (T) {
    var M = 0
    var S = 1
    for (; T; T = Math.floor(T / 10)) S = (S + (T % 10) * (9 - (M++ % 6))) % 11
    return S ? S - 1 : 'k'
  }
}

function emailValidation(correo) {
  let regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
  return regex.test(correo)
}
async function setProjects() {
  if (!props.projectId && props.customer) {
    try {
      loading.value = true
      const apiVersion = props.version === 1 ? 'v2' : 'v3'
      const response = await axios.get(
        `https://cotizacion.mobysuite.com/api/${apiVersion}/quotes/list-projects?real_estate=` +
          props.customer
      )
      projects.value = response.data.filter((project) => project.activoCotizadorWeb)
      contact.projectId.value = projects.value[0].id
      loading.value = false
    } catch (error) {
      loading.value = false
      console.log(error)
    }
  }
}

onMounted(() => {
  getParamsFromURL()
  setProjects()
})
</script>

<template>
  <div
    class="mobysuite-web-contact-w-full mobysuite-web-contact-relative mobysuite-web-contact-flex mobysuite-web-contact-justify-center mobysuite-web-contact-items-center"
  >
    <div
      :class="{
        'mobysuite-web-contact-w-full flex justify-center position-relative': true
      }"
    >
      <div
        :class="[
          { 'mobysuite-web-contact-w-full': true },
          {
            'mobysuite-web-contact-pointer-events-none mobysuite-web-contact-select-none mobysuite-web-contact-blur-sm':
              loading
          }
        ]"
      >
        <h2
          class="mobysuite-web-contact-text-4xl mobysuite-web-contact-font-extrabold mobysuite-web-contact-text-black"
          v-if="title"
        >
          {{ title }}
        </h2>
        <p
          class="mobysuite-web-contact-text-lg mobysuite-web-contact-text-gray-500 mobysuite-web-contact-mb-3"
          v-if="subtitle"
        >
          {{ subtitle }}
        </p>
        <div class="mobysuite-web-contact-py-3">
          <div
            class="mobysuite-web-contact-flex mobysuite-web-contact-flex-wrap mobysuite-web-contact--mx-3 mobysuite-web-contact-mb-6"
          >
            <div
              v-if="projects.length > 1"
              class="mobysuite-web-contact-w-full md:mobysuite-web-contact-w-1 mobysuite-web-contact-px-3 mobysuite-web-contact-mb-6 md:mobysuite-web-contact-mb-2"
            >
              <label
                class="mobysuite-web-contact-block mobysuite-web-contact-uppercase mobysuite-web-contact-tracking-wide mobysuite-web-contact-text-gray-700 mobysuite-web-contact-text-xs mobysuite-web-contact-font-bold mobysuite-web-contact-mb-2"
                for="grid-first-name"
              >
                Proyecto
              </label>
              <select
                v-if="projects"
                v-model="contact.projectId.value"
                :disabled="loading"
                :class="
                  !contact.projectId.value && contact.projectId.alertStatus === 'alert'
                    ? 'mobysuite-web-contact-border-red-500'
                    : 'mobysuite-web-contact-border-gray-200'
                "
                class="mobysuite-web-contact-block mobysuite-web-contact-w-full mobysuite-web-contact-bg-gray-200 mobysuite-web-contact-text-gray-700 mobysuite-web-contact-border mobysuite-web-contact-rounded mobysuite-web-contact-py-3 mobysuite-web-contact-px-4 mobysuite-web-contact-mb-3 mobysuite-web-contact-leading-tight"
                id="grid-first-name"
                type="text"
                placeholder="Selecciona un proyecto"
              >
                <option
                  v-for="(project, key) in projects"
                  :key="`project${key}`"
                  :value="project.id"
                >
                  {{ project.nombreProyecto }}
                </option>
              </select>
              <p
                v-if="!contact.name.value && contact.name.alertStatus === 'alert'"
                class="mobysuite-web-contact-text-red-500 mobysuite-web-contact-text-xs mobysuite-web-contact-italic"
              >
                El proyecto es requerido.
              </p>
            </div>
            <div
              class="mobysuite-web-contact-w-full md:mobysuite-web-contact-w-1/2 mobysuite-web-contact-px-3 mobysuite-web-contact-mb-6 md:mobysuite-web-contact-mb-0"
            >
              <label
                class="mobysuite-web-contact-block mobysuite-web-contact-uppercase mobysuite-web-contact-tracking-wide mobysuite-web-contact-text-gray-700 mobysuite-web-contact-text-xs mobysuite-web-contact-font-bold mobysuite-web-contact-mb-2"
                for="grid-first-name"
              >
                Nombre
              </label>
              <input
                v-model="contact.name.value"
                :disabled="loading"
                :class="
                  !contact.name.value && contact.name.alertStatus === 'alert'
                    ? 'mobysuite-web-contact-border-red-500'
                    : 'mobysuite-web-contact-border-gray-200'
                "
                class="mobysuite-web-contact-appearance-none mobysuite-web-contact-block mobysuite-web-contact-w-full mobysuite-web-contact-bg-gray-200 mobysuite-web-contact-text-gray-700 mobysuite-web-contact-border mobysuite-web-contact-rounded mobysuite-web-contact-py-3 mobysuite-web-contact-px-4 mobysuite-web-contact-mb-3 mobysuite-web-contact-leading-tight"
                id="grid-first-name"
                type="text"
                placeholder="Ingresa tu nombre"
                @keyup="contact.name.alertStatus = 'initial'"
              />
              <p
                v-if="!contact.name.value && contact.name.alertStatus === 'alert'"
                class="mobysuite-web-contact-text-red-500 mobysuite-web-contact-text-xs mobysuite-web-contact-italic"
              >
                El campo nombre es requerido.
              </p>
            </div>
            <div
              class="mobysuite-web-contact-w-full md:mobysuite-web-contact-w-1/2 mobysuite-web-contact-px-3"
            >
              <label
                class="mobysuite-web-contact-block mobysuite-web-contact-uppercase mobysuite-web-contact-tracking-wide mobysuite-web-contact-text-gray-700 mobysuite-web-contact-text-xs mobysuite-web-contact-font-bold mobysuite-web-contact-mb-2"
                for="grid-last-name"
              >
                Apellido
              </label>
              <input
                v-model="contact.lastName.value"
                :disabled="loading"
                :class="
                  !contact.lastName.value && contact.lastName.alertStatus === 'alert'
                    ? 'mobysuite-web-contact-border-red-500'
                    : 'mobysuite-web-contact-border-gray-200'
                "
                class="mobysuite-web-contact-appearance-none mobysuite-web-contact-block mobysuite-web-contact-w-full mobysuite-web-contact-bg-gray-200 mobysuite-web-contact-text-gray-700 mobysuite-web-contact-border mobysuite-web-contact-rounded mobysuite-web-contact-py-3 mobysuite-web-contact-px-4 mobysuite-web-contact-mb-3 mobysuite-web-contact-leading-tight"
                id="grid-last-name"
                type="text"
                placeholder="Ingresa tu apellido"
                @keyup="contact.lastName.alertStatus = 'initial'"
              />
              <p
                v-if="!contact.lastName.value && contact.lastName.alertStatus === 'alert'"
                class="mobysuite-web-contact-text-red-500 mobysuite-web-contact-text-xs mobysuite-web-contact-italic"
              >
                El campo apellido es requerido.
              </p>
            </div>
          </div>
          <div
            class="mobysuite-web-contact-flex mobysuite-web-contact-flex-wrap mobysuite-web-contact--mx-3 mobysuite-web-contact-mb-6"
          >
            <div
              class="mobysuite-web-contact-w-full md:mobysuite-web-contact-w-1/2 mobysuite-web-contact-px-3"
            >
              <label
                class="mobysuite-web-contact-block mobysuite-web-contact-uppercase mobysuite-web-contact-tracking-wide mobysuite-web-contact-text-gray-700 mobysuite-web-contact-text-xs mobysuite-web-contact-font-bold mobysuite-web-contact-mb-2"
                for="rut"
              >
                Rut
              </label>
              <input
                :class="
                  !Fn.validaRut(contact.rut.value) && contact.rut.alertStatus === 'alert'
                    ? 'mobysuite-web-contact-border-red-500'
                    : 'mobysuite-web-contact-border-gray-200'
                "
                :disabled="loading"
                class="mobysuite-web-contact-appearance-none mobysuite-web-contact-block mobysuite-web-contact-w-full mobysuite-web-contact-bg-gray-200 mobysuite-web-contact-text-gray-700 mobysuite-web-contact-border mobysuite-web-contact-border-gray-200 mobysuite-web-contact-rounded mobysuite-web-contact-py-3 mobysuite-web-contact-px-4 mobysuite-web-contact-mb-3 mobysuite-web-contact-leading-tight"
                id="grid-rut"
                placeholder="11.111.111-1"
                v-model="contact.rut.value"
                @keyup=";(contact.rut.alertStatus = 'initial'), formateaRut(contact.rut.value)"
              />
              <p
                v-if="!Fn.validaRut(contact.rut.value) && contact.rut.alertStatus === 'alert'"
                class="mobysuite-web-contact-text-red-500 mobysuite-web-contact-text-xs mobysuite-web-contact-italic"
              >
                Ingrese un rut válido.
              </p>
            </div>
            <div
              class="mobysuite-web-contact-w-full md:mobysuite-web-contact-w-1/2 mobysuite-web-contact-px-3"
            >
              <label
                class="mobysuite-web-contact-block mobysuite-web-contact-uppercase mobysuite-web-contact-tracking-wide mobysuite-web-contact-text-gray-700 mobysuite-web-contact-text-xs mobysuite-web-contact-font-bold mobysuite-web-contact-mb-2"
                for="grid-email"
              >
                Email
              </label>
              <input
                v-model="contact.email.value"
                :disabled="loading"
                :class="
                  !emailValidation(contact.email.value) && contact.email.alertStatus === 'alert'
                    ? 'mobysuite-web-contact-border-red-500'
                    : 'mobysuite-web-contact-border-gray-200'
                "
                class="mobysuite-web-contact-appearance-none mobysuite-web-contact-block mobysuite-web-contact-w-full mobysuite-web-contact-bg-gray-200 mobysuite-web-contact-text-gray-700 mobysuite-web-contact-border mobysuite-web-contact-rounded mobysuite-web-contact-py-3 mobysuite-web-contact-px-4 mobysuite-web-contact-mb-3 mobysuite-web-contact-leading-tight"
                id="grid-email"
                type="text"
                placeholder="Ingresa tu correo"
                @keyup="contact.email.alertStatus = 'initial'"
              />
              <p
                v-if="
                  !emailValidation(contact.email.value) && contact.email.alertStatus === 'alert'
                "
                class="mobysuite-web-contact-text-red-500 mobysuite-web-contact-text-xs mobysuite-web-contact-italic"
              >
                Ingresa un email válido.
              </p>
            </div>
          </div>
          <div
            class="mobysuite-web-contact-flex mobysuite-web-contact-flex-wrap mobysuite-web-contact--mx-3 mobysuite-web-contact-mb-6"
          >
            <div class="mobysuite-web-contact-w-full mobysuite-web-contact-px-3">
              <label
                class="mobysuite-web-contact-block mobysuite-web-contact-uppercase mobysuite-web-contact-tracking-wide mobysuite-web-contact-text-gray-700 mobysuite-web-contact-text-xs mobysuite-web-contact-font-bold mobysuite-web-contact-mb-2"
                for="grid-password"
              >
                Teléfono
              </label>
              <vue-tel-input
                :inputOptions="cellphoneOptions"
                :disabled="loading"
                :autoFormat="true"
                :preferredCountries="preferredCountries"
                :dropdownOptions="{
                  showDialCodeInList: true,
                  showDialCodeInSelection: true,
                  showFlags: true
                }"
                :class="[
                  'mobysuite-web-contact-appearance-none mobysuite-web-contact-w-full mobysuite-web-contact-bg-gray-200 mobysuite-web-contact-text-gray-700 mobysuite-web-contact-border mobysuite-web-contact-border-gray-200 mobysuite-web-contact-rounded mobysuite-web-contact-py-2 mobysuite-web-contact-px-2 mobysuite-web-contact-mb-3 mobysuite-web-contact-leading-tight',
                  !contact.cellphone.isValid && contact.cellphone.alertStatus === 'alert'
                    ? 'mobysuite-web-contact-border-red-500'
                    : 'mobysuite-web-contact-border-gray-200'
                ]"
                v-model="contact.cellphone.value"
                v-on:country-changed="countryChanged"
                :auto-default-country="false"
                @validate="yourValidationMethod"
                @input="contact.cellphone.alertStatus = 'initial'"
              ></vue-tel-input>
              <p
                v-if="!contact.cellphone.isValid && contact.cellphone.alertStatus === 'alert'"
                class="mobysuite-web-contact-text-red-500 mobysuite-web-contact-text-xs mobysuite-web-contact-italic"
              >
                Ingrese un teléfono válido.
              </p>
            </div>
          </div>
          <div class="mobysuite-web-contact-flex mobysuite-web-contact-justify-end">
            <button
              class="mobysuite-web-contact-flex-shrink-0 mobysuite-web-contact-text-xl mobysuite-web-contact-border-4 mobysuite-web-contact-py-2 mobysuite-web-contact-px-7 mobysuite-web-contact-rounded-xl mobysuite-web-contact-transition-all send-button"
              :style="{
                backgroundColor: phone
                  ? modalHeaderAndButtonBackgroundColor
                    ? modalHeaderAndButtonBackgroundColor
                    : '#25d366'
                  : modalHeaderAndButtonBackgroundColor
                  ? modalHeaderAndButtonBackgroundColor
                  : 'black',
                border: `2px solid ${
                  phone
                    ? modalHeaderAndButtonBackgroundColor
                      ? modalHeaderAndButtonBackgroundColor
                      : '#25d366'
                    : modalHeaderAndButtonBackgroundColor
                    ? modalHeaderAndButtonBackgroundColor
                    : 'black'
                }`,
                color: buttonTextColor
              }"
              type="button"
              :disabled="loading"
              @click="sendProspect"
            >
              Enviar
            </button>
          </div>
        </div>
      </div>
      <div
        v-if="loading && !sended"
        class="mobysuite-web-contact-absolute mobysuite-web-contact-top-0 mobysuite-web-contact-w-full mobysuite-web-contact-h-full mobysuite-web-contact-flex mobysuite-web-contact-justify-center mobysuite-web-contact-items-center"
      >
        <div role="status">
          <svg
            aria-hidden="true"
            class="mobysuite-web-contact-w-8 mobysuite-web-contact-h-8 mobysuite-web-contact-text-gray-200 mobysuite-web-contact-animate-spin dark:mobysuite-web-contact-text-gray-600 mobysuite-web-contact-fill-blue-600"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span class="mobysuite-web-contact-sr-only">Loading...</span>
        </div>
      </div>
      <div
        v-else-if="!loading && sended"
        class="mobysuite-web-contact-absolute mobysuite-web-contact-top-0 mobysuite-web-contact-w-full mobysuite-web-contact-h-full mobysuite-web-contact-flex mobysuite-web-contact-justify-center mobysuite-web-contact-items-center mobysuite-web-contact-bg-white"
      >
        <div
          class="mobysuite-web-contact-flex mobysuite-web-contact-flex-col mobysuite-web-contact-justify-center mobysuite-web-contact-items-center mobysuite-web-contact-text-center"
        >
          <h2 class="mobysuite-web-contact-text-5xl mobysuite-web-contact-text-slate-500">
            {{ !formError ? successTitle : '¡Ocurrió un error!' }}
          </h2>
          <SuccessIcon
            v-if="!formError"
            class="mobysuite-web-contact-my-10"
            :color="phone ? 'rgb(37, 211, 102)' : 'black'"
          />

          <p
            class="mobysuite-web-contact-text-2xl mobysuite-web-contact-text-slate-500"
            :class="{ 'mobysuite-web-contact-mt-5': formError }"
          >
            {{ !formError ? successSubtitle : 'Por favor intentalo de nuevo más tarde' }}
          </p>
          <button
            v-if="formError"
            class="mobysuite-web-contact-flex-shrink-0 mobysuite-web-contact-text-xl mobysuite-web-contact-border-4 mobysuite-web-contact-py-2 mobysuite-web-contact-px-7 mobysuite-web-contact-rounded-xl mobysuite-web-contact-transition-all mobysuite-web-contact-mt-5 send-button"
            :style="{
              backgroundColor: phone
                ? modalHeaderAndButtonBackgroundColor
                  ? modalHeaderAndButtonBackgroundColor
                  : '#25d366'
                : modalHeaderAndButtonBackgroundColor
                ? modalHeaderAndButtonBackgroundColor
                : 'black',
              border: `2px solid ${
                phone
                  ? modalHeaderAndButtonBackgroundColor
                    ? modalHeaderAndButtonBackgroundColor
                    : '#25d366'
                  : modalHeaderAndButtonBackgroundColor
                  ? modalHeaderAndButtonBackgroundColor
                  : 'black'
              }`,
              color: buttonTextColor
            }"
            type="button"
            :disabled="loading"
            @click=";(formError = false), (sended = false)"
          >
            Reintentar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.vti__input {
  color: rgb(55 65 81 / var(--tw-text-opacity));
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
  --tw-bg-opacity: 1 !important;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity)) !important;
}
.vti__dropdown-list.below {
  top: auto !important;
  bottom: 100% !important;
  width: 30vw !important;
}
@media (max-width: 992px) {
  .vti__dropdown-list.below {
    width: 60vw !important;
  }
}
</style>
<style>
.send-button:hover {
  opacity: 0.8;
}
</style>
